@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader {
  border-top-color: #3498db;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}
